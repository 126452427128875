<template>
  <div class="container-fluid">
    <br />
    <div
      style="display: flex;justify-content: space-between;align-items: center;"
    >
      <div>
        <h3>Informes us menjador escolar</h3>
        <h5>{{ centre.nom }}</h5>
      </div>
      <div>
        <Button
          v-if="user?.empresa_menjador_id"
          type="button"
          class="btn btn-secondary btn-sm p-text-right"
          @click="$router.push('/centres')"
          >Tornar</Button
        >&nbsp;
      </div>
    </div>

    <Button
      type="button"
      class="btn btn-secondary btn-sm"
      @click="openNouInforme"
    >
      <font-awesome-icon icon="plus" /> Nou informe
    </Button>
    <br /><br />

    <table class="table">
      <thead>
        <tr>
          <th scope="col">Mes/Any</th>
          <th scope="col">Tipus</th>
          <th scope="col">Num alumnes</th>
          <th scope="col">70%</th>
          <th scope="col">100%</th>
          <th scope="col">Compact</th>
          <th scope="col">Import total</th>
          <th scope="col">Estat</th>
          <th scope="col">Accions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(informe, index) in informes" :key="index">
          <th scope="row" style="width: 100px">
            {{ informe.mes }} / {{ informe.any }}
          </th>
          <td>{{ informe.tipus == 1 ? "Beques" : "Preceptius" }}</td>
          <td>{{ informe.numAlumnes }}</td>
          <td>{{ informe.suma70 }}</td>
          <td>{{ informe.suma100 }}</td>
          <td>{{ informe.sumaImportCompactacio }}</td>
          <td>{{ informe.sumaImportTotal }}</td>
          <td :class="`estat-${informe.estat}`">
            {{ informe.estatText }}<br />
            <span class="textRetorn" v-if="informe.estat == -1">{{
              informe.motiuRetorn
            }}</span>
          </td>
          <td style="width: 300px">
            <router-link
              :to="`/informe/${informe.id}`"
              class="nav-link floatLeft noMargin"
            >
              <button
                v-if="informe.estat < 2"
                type="button"
                class="btn btn-secondary btn-sm"
              >
                <font-awesome-icon icon="pen" />
                Marcar dies
              </button>
            </router-link>
            <button
              v-if="informe.estat < 2"
              type="button"
              class="btn btn-success btn-sm floatLeft"
              @click="enviarCCB(informe.id)"
            >
              Enviar Informe
            </button>
            <button
              v-if="informe.estat < 2"
              type="button"
              class="btn btn-danger btn-sm floatLeft"
              @click="eliminar(informe.id)"
            >
              <font-awesome-icon icon="trash" />
            </button>
            <a :href="`${baseUrl}gen-pdf/${informe.id}?token=${accessToken}`">
              <Button
                v-if="informe.estat >= 2"
                type="button"
                class="btn btn-secondary btn-sm p-text-right"
                ><font-awesome-icon icon="file-pdf" /> Informe PDF</Button
              ></a
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <Dialog
    header="Nou informe"
    v-model:visible="showNouInforme"
    :style="{ width: '30vw' }"
    :modal="true"
    :draggable="false"
  >
    <div v-if="nouInforme.step == 0">
      <div class="p-field p-grid">
        <label for="firstname" style="width: 140px">Data informe</label>
        <div class="p-col">
          <Dropdown
            v-model="nouInforme.mes"
            :options="llistats.mesos"
            optionLabel="nom"
            placeholder="Mes"
          />&nbsp;
          <Dropdown
            v-model="nouInforme.any"
            :options="llistats.anys"
            optionLabel="nom"
            placeholder="Any"
          />
        </div>
      </div>
      <br />
      <div class="p-field p-grid">
        <label for="lastname" class="p-col-fixed" style="width: 140px"
          >Tipus informe</label
        >
        <div class="p-col" style="width: 140px">
          <Dropdown
            v-model="nouInforme.tipusInforme"
            :options="llistats.tipusInforme"
            optionLabel="nom"
            placeholder="Tipus informe"
          />
        </div>
      </div>
      <br />
      <div class="p-field p-grid">
        <label for="lastname" class="p-col-fixed" style="width: 140px"
          >Preu menjador: {{ centre?.importMenjador?.toFixed(2) }}€</label
        >
      </div>
    </div>
    <div v-if="nouInforme.step == 1">
      <h3>
        Generant informe...<font-awesome-icon
          icon="spinner"
          spin
          style="width: 50px"
        />
      </h3>
      <h3></h3>
    </div>
    <div v-if="nouInforme.step == 2">
      <h3>Generant informe: OK</h3>
      <h3>
        Carregant llistat alumnes...<font-awesome-icon
          icon="spinner"
          spin
          style="width: 50px"
        />
      </h3>
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        @click="closeModals"
        class="p-button-text"
      />
      <Button
        v-if="nouInforme.step == 0"
        label="Crear nou informe"
        icon="pi pi-check"
        @click="handleCrearNouInforme"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import InformeService from "@/services/informes.service.js";
export default {
  name: "User",

  data() {
    return {
      informes: [],
      baseUrl: "",
      user: null,
      accessToken: null,
      content: "",
      showNouInforme: false,
      centre: { id: 0, nom: "Carregant", preuMenjador: 0, preuBeca: 0 },
      nouInforme: {
        step: 0,
        mes: { id: 9, nom: "Setembre" },
        any: null,
        id_centre: 0,
        tipusInforme: { id: 1, nom: "Certificat menjador AIM" },
        preuMenjador: 6.54,
        preuBeca: 6.54,
      },
      llistats: {
        mesos: [
          { id: 1, nom: "Gener" },
          { id: 2, nom: "Febrer" },
          { id: 3, nom: "Març" },
          { id: 4, nom: "Abril" },
          { id: 5, nom: "Maig" },
          { id: 6, nom: "Juny" },
          { id: 7, nom: "Juliol" },
          { id: 8, nom: "Agost" },
          { id: 9, nom: "Setembre" },
          { id: 10, nom: "Octubre" },
          { id: 11, nom: "Novembre" },
          { id: 12, nom: "Desembre" },
        ],
        anys: [],
        tipusInforme: [
          { id: 1, nom: "Certificat menjador AIM" },
          { id: 2, nom: "Certificat menjador preceptius" },
        ],
      },
    };
  },
  methods: {
    openNouInforme: function() {
      this.nouInforme.step = 0;
      this.nouInforme.preuMenjador = this.centre.importMenjador;
      this.nouInforme.preuBeca = this.centre.importBeca;

      this.showNouInforme = true;
    },
    handleCrearNouInforme() {
      let that = this;
      that.nouInforme.step = 1;
      InformeService.saveNouInforme(this.nouInforme).then(
        (response) => {
          that.nouInforme.step = 2;
          InformeService.fillNouInforme(response.data.informe).then(
            (response) => {
              let informeCreated = response.data.informe;
              informeCreated.alumnes = response.data.alumnes;
              console.log(response);
              that.closeModals();
              that.updateInformesList();
              InformeService.saveInforme(informeCreated).then(
                () => {
                  this.$router.push("/informe/" + informeCreated.id);
                },
                (error) => {
                  console.log(error);
                }
              );
            },
            (error) => {
              console.log(error);
              that.nouInforme.step = -1;
            }
          );
        },
        (error) => {
          console.log(error);
          that.closeModals();
        }
      );
    },
    enviarCCB(idInforme) {
      if (
        confirm(
          "Segur que voleu enviar l'informe? ja no es podran efectuar més modificacions"
        )
      ) {
        const isLocalhost = process.env.VUE_APP_IS_LOCALHOST == 1 ? 1 : 0;
        const url = `${process.env.VUE_APP_BACK}enviar-informe?token=${this.accessToken}&idInforme=${idInforme}&isLocalhost=${isLocalhost}`;
        window.location.href = url;
      }
    },

    eliminar(id) {
      let that = this;
      if (
        confirm(
          "Segur que voleu eliminar l'informe? aquesta acció no es pot desfer"
        )
      ) {
        InformeService.eliminarInforme(id).then(
          () => {
            that.updateInformesList();
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    closeModals() {
      this.showNouInforme = false;
    },
    updateInformesList() {
      let user = JSON.parse(localStorage.getItem("user"));
      this.nouInforme.id_centre = user.user.id_centre;
      if (this.$route.params.centre) {
        this.nouInforme.id_centre = this.$route.params.centre;
      }

      if (!this.nouInforme.id_centre) {
        this.$router.push(
          this.user.empresa_menjador_id ? "/centres" : "/login"
        );
      }

      let that = this;
      InformeService.getInformes(this.nouInforme.id_centre).then(
        (response) => {
          that.informes = response.data.informes;
        },
        () => {
          this.$store.dispatch("auth/logout");
          this.$router.push("/login");
        }
      );
    },
  },
  async mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.user = user.user;
    if (!this.$route.params.centre && user.user.empresa_menjador_id) {
      return this.$router.replace("/centres");
    }

    this.baseUrl = process.env.VUE_APP_API_URL;
    this.updateInformesList();
    let that = this;
    if (user.access_token) {
      this.accessToken = user.access_token;
    }
    let centreId = user.user.id_centre;
    if (this.$route.params.centre) {
      centreId = this.$route.params.centre;
    }
    InformeService.getCentre(centreId).then(
      (response) => {
        that.centre = response.data.centre;
        that.llistats.anys = response.data.anys;
        that.nouInforme.any = response.data.currentAny
          ? response.data.currentAny
          : that.anys.length > 0
          ? that.anys.anys[0]
          : null;
        //that.centre.preuBeca = float.parse(that.centre.importMenjador > 6.54 ? 6.54 : that.centre.preuMenjador;
      },
      () => {}
    );
  },
};
</script>
<style scoped>
.td {
  margin: 0px !important;
}
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}
.estat-0,
.estat--1 {
  color: #d62d20;
}
.estat-1 {
  color: #d66320;
}
.estat-2,
.estat-3,
.estat-4,
.estat-5 {
  color: cadetblue;
}
.floatLeft {
  float: left !important;
}
.noMargin {
  margin: 0px 2px 0px 0px;
  padding: 0px;
}
.textRetorn {
  font-size: 9px;
  font-style: italic;
  line-height: 9px;
  padding: 0px;
  margin: 0px;
}
</style>
